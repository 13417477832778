<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <!-- end:: "Go Back" button -->

    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Ficha de estudiante
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar la ficha de datos del estudiante
              seleccionado.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->

        <!-- begin:: Student record view  -->

        <!-- begin::autocomplete select for student -->
        <v-row>
          <v-col>
            <v-autocomplete
              :label="
                areStudentsLoading
                  ? 'Cargado estudiantes...'
                  : 'Código del estudiante'
              "
              :loading="areStudentsLoading"
              :disabled="
                areStudentsLoading || !students.length || !onlyOneStudent
              "
              outlined
              hide-details
              open-on-clear
              clearable
              :items="students"
              item-text="code"
              item-value="code"
              return-object
              v-model="report.params.student"
              @change="get(report.params.student)"
              @click="wipe()"
            >
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.full_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item, index }">
                <v-list-item-content class="student-divider">
                  <v-row>
                    <!-- student photo -->
                    <v-col
                      cols="2"
                      lg="1"
                      class="d-flex justify-start align-start justify-lg-end alignxl-lg-end"
                    >
                      <v-avatar size="65">
                        <img
                          :src="`${item.photo}`"
                          :alt="`${item.full_name}`"
                        />
                      </v-avatar>
                    </v-col>
                    <!-- student details -->
                    <v-col cols="10" lg="11">
                      <v-list-item-title>
                        <span class="font-weight-medium">
                          {{ item.code }}
                        </span>
                        -
                        <span class="text-uppercase font-weight-medium">
                          {{ item.full_name }}
                        </span>
                        <v-chip
                          x-small
                          class="ma-2"
                          :color="
                            `${
                              item.status == 'Activo'
                                ? 'success'
                                : 'red darken-1'
                            }`
                          "
                          outlined
                        >
                          <span class="font-weight-bold">{{
                            item.status
                          }}</span>
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1">
                        <span>
                          Grado
                          <strong class="ml-1">{{ item.grade }}</strong>
                        </span>
                        <span class="ml-2">
                          Sección
                          <strong class="ml-1">{{ item.section_group }}</strong>
                        </span>
                        <span class="ml-2">
                          Especialidad
                          <strong class="ml-1">{{
                            item.technical_group
                          }}</strong>
                        </span>
                      </v-list-item-subtitle></v-col
                    >
                  </v-row>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- end::autocomplete select for student -->

        <!-- begin::selected student card -->
        <v-row v-if="isCardVisible">
          <v-col cols="12" sm="6" xl="3" class="mt-4 mx-auto">
            <div
              class="elevation-2 rounded-lg subject-card d-flex flex-column align-stretch justify-center"
            >
              <div
                class="px-4 pb-0 d-flex flex-column justify-center align-start justify-md-start flex-md-row"
              >
                <div
                  :class="
                    `${
                      colors[grade % 12]
                    } card-icon-container lighten-4 d-flex rounded-circle mt-6 mt-md-0 mx-auto mx-md-0 pa-5 mb-3 mb-md-0
                      `
                  "
                >
                  <v-icon class="mx-auto" :color="`${colors[grade % 12]}`" large
                    >mdi-file-account-outline</v-icon
                  >
                </div>
                <!-- texto -->
                <div class="mx-auto ml-md-5 mt-md-2 mb-6 mb-md-0">
                  <p
                    class="ma-0 mb-0 text-h6 font-weight-bold text-center text-md-left"
                  >
                    {{ studentFirstName }} <br />
                    {{ studentLastName }}
                  </p>
                  <p
                    class="text-body-1 blue-grey--text text--lighten-1 ma-0 text-center text-md-left mt-2 mt-md-0"
                  >
                    {{ studentCode }}
                  </p>
                </div>
              </div>
              <!-- action button -->

              <v-btn
                class="mx-5 mt-xl-4 mb-4 mb-xl-0"
                text
                @click="getStudentRecord()"
              >
                <v-icon color="red" class="mr-1">mdi-file-pdf-box</v-icon>
                Generar ficha</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <!-- end::selected student card -->

        <!-- begin::fallback if there isn't any student selected -->
        <div v-else class="mt-10">
          <v-row>
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
              <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                <v-icon large color="red">mdi-account-group-outline</v-icon>
              </div>
              <div>
                <p class="text-center font-weight-bold text-h6 mb-1">
                  Seleccione un estudiante o ingrese un código de carnet
                </p>
                <p class="text-center font-weight-medium text-body-1">
                  Al seleccionar un estudiante o ingresar un código de carnet,
                  podrá generar la ficha de datos del estudiante.
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- end::fallback if there isn't any student selected -->

        <!-- end:: Student record view  -->
      </template>
    </div>
  </div>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "EvaluationSummary",
  title: "Ficha de estudiante | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notas", route: "view_subjects_modules" },
      { title: "Resumen de notas", route: "evaluation_summary" },
    ]);
    this.getStudents();
  },
  data() {
    return {
      isCardVisible: false,
      onlyOneStudent: true,
      areStudentsLoading: false,
      filteredStudent: [],
      students: [],
      selectedStudent: {},
      report: {
        params: {},
      },
    };
  },

  methods: {
    //fetch all students
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformationIncludingDisabled()
        .then(({ data }) => {
          this.students = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    //--------------Flow and validations--------------

    /*If there's a code available when an "onChange" event takes
      place, the request will be made. otherwise, dont.*/
    get(student) {
      if (student != null) {
        //request functions
        this.showCard();
        return;
      }
      this.isCardVisible = false;
    },

    wipe() {
      this.report.params = {};
      this.isCardVisible = false;
    },

    showCard() {
      this.isCardVisible = true;
      this.selectedStudent = { ...this.report.params?.student };
    },

    // -------------------Actions---------------------

    getStudentRecord() {
      let url;
      url = `${this.baseURL}/students/file/${this.studentUUID}`;
      window.open(url, "_blank");
      return;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    baseURL() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
    studentFirstName() {
      let firstName = this.selectedStudent.first_name;
      return !!firstName ? firstName : "Nombre";
    },
    studentLastName() {
      let lastName = this.selectedStudent.last_name;
      return !!lastName ? lastName : "Apellido";
    },
    studentCode() {
      let code = this.selectedStudent.code;
      return !!code ? code : "XXXXXXXX";
    },
    studentUUID() {
      let studentUUID = this.selectedStudent.uuid;
      return !!studentUUID ? studentUUID : "no-uuid";
    },
    grade() {
      let grade = this.selectedStudent.grade_id;
      return !!grade ? grade : "8";
    },
  },
};
</script>

<style>
.student-divider {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.box {
  aspect-ratio: 1/1;
}

.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  min-height: 95px;
  min-width: 95px;
}

.module-state-indicator {
  position: absolute;
  bottom: 13px;
}

.subject-card {
  min-height: 180px;
}

@media (max-width: 960px) {
  .module-state-indicator {
    top: 13px;
  }
}

@media (max-width: 350px) {
  .action-buttons-container {
    flex-direction: column-reverse !important;
  }

  .update-btn {
    margin-bottom: 1em !important;
  }

  .fallback-alert {
    display: none !important;
  }
}
</style>
