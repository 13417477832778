var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Regresar a reportes ")],1),_c('div',{staticClass:"white rounded-lg pa-5 py-md-8 px-md-10"},[[_vm._m(0),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":_vm.areStudentsLoading
                ? 'Cargado estudiantes...'
                : 'Código del estudiante',"loading":_vm.areStudentsLoading,"disabled":_vm.areStudentsLoading || !_vm.students.length || !_vm.onlyOneStudent,"outlined":"","hide-details":"","open-on-clear":"","clearable":"","items":_vm.students,"item-text":"code","item-value":"code","return-object":""},on:{"change":function($event){return _vm.get(_vm.report.params.student)},"click":function($event){return _vm.wipe()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.full_name))])]):_vm._e()]}},{key:"item",fn:function({ item, index }){return [_c('v-list-item-content',{staticClass:"student-divider"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-start justify-lg-end alignxl-lg-end",attrs:{"cols":"2","lg":"1"}},[_c('v-avatar',{attrs:{"size":"65"}},[_c('img',{attrs:{"src":`${item.photo}`,"alt":`${item.full_name}`}})])],1),_c('v-col',{attrs:{"cols":"10","lg":"11"}},[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.code)+" ")]),_vm._v(" - "),_c('span',{staticClass:"text-uppercase font-weight-medium"},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":`${
                            item.status == 'Activo'
                              ? 'success'
                              : 'red darken-1'
                          }`,"outlined":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.status))])])],1),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('span',[_vm._v(" Grado "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.grade))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Sección "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.section_group))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Especialidad "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.technical_group))])])])],1)],1)],1)]}}]),model:{value:(_vm.report.params.student),callback:function ($$v) {_vm.$set(_vm.report.params, "student", $$v)},expression:"report.params.student"}})],1)],1),(_vm.isCardVisible)?_c('v-row',[_c('v-col',{staticClass:"mt-4 mx-auto",attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('div',{staticClass:"elevation-2 rounded-lg subject-card d-flex flex-column align-stretch justify-center"},[_c('div',{staticClass:"px-4 pb-0 d-flex flex-column justify-center align-start justify-md-start flex-md-row"},[_c('div',{class:`${
                    _vm.colors[_vm.grade % 12]
                  } card-icon-container lighten-4 d-flex rounded-circle mt-6 mt-md-0 mx-auto mx-md-0 pa-5 mb-3 mb-md-0
                    `},[_c('v-icon',{staticClass:"mx-auto",attrs:{"color":`${_vm.colors[_vm.grade % 12]}`,"large":""}},[_vm._v("mdi-file-account-outline")])],1),_c('div',{staticClass:"mx-auto ml-md-5 mt-md-2 mb-6 mb-md-0"},[_c('p',{staticClass:"ma-0 mb-0 text-h6 font-weight-bold text-center text-md-left"},[_vm._v(" "+_vm._s(_vm.studentFirstName)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.studentLastName)+" ")]),_c('p',{staticClass:"text-body-1 blue-grey--text text--lighten-1 ma-0 text-center text-md-left mt-2 mt-md-0"},[_vm._v(" "+_vm._s(_vm.studentCode)+" ")])])]),_c('v-btn',{staticClass:"mx-5 mt-xl-4 mb-4 mb-xl-0",attrs:{"text":""},on:{"click":function($event){return _vm.getStudentRecord()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red"}},[_vm._v("mdi-file-pdf-box")]),_vm._v(" Generar ficha")],1)],1)])],1):_c('div',{staticClass:"mt-10"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto d-flex flex-column",attrs:{"cols":"12","sm":"10"}},[_c('div',{staticClass:"mx-auto mb-4 pa-8 rounded-circle red lighten-4"},[_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v("mdi-account-group-outline")])],1),_c('div',[_c('p',{staticClass:"text-center font-weight-bold text-h6 mb-1"},[_vm._v(" Seleccione un estudiante o ingrese un código de carnet ")]),_c('p',{staticClass:"text-center font-weight-medium text-body-1"},[_vm._v(" Al seleccionar un estudiante o ingresar un código de carnet, podrá generar la ficha de datos del estudiante. ")])])])],1)],1)]],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Ficha de estudiante "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Aqui se podra visualizar la ficha de datos del estudiante seleccionado.")])])])
}]

export { render, staticRenderFns }